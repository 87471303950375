<template>
  <div>
    <div class="AsideMenu">
      <el-menu :default-active="active" class="el-menu-vertical-demo" @select="handleSelect">
        <!-- <el-menu-item index="1">
          <i class="el-icon-menu"></i>
          <span slot="title">学术简讯</span>
        </el-menu-item> -->
        <el-menu-item index="6">
          <i class="el-icon-money"></i>
          <span slot="title">机构概况</span>
        </el-menu-item>
        <el-menu-item index="7">
          <i class="el-icon-tickets"></i>
          <span slot="title">会议新闻</span>
        </el-menu-item>
        <el-menu-item index="5">
          <i class="el-icon-s-order"></i>
          <span slot="title">质控调研</span>
        </el-menu-item>

        <el-menu-item index="2">
          <i class="el-icon-c-scale-to-original"></i>
          <span slot="title">学习培训</span>
        </el-menu-item>

        <!-- <el-menu-item index="8">
          <i class="el-icon-s-custom"></i>
          <span slot="title">学习与讨论</span>
        </el-menu-item> -->

      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "AsideMenu",
  data() {
    return {
      loginInfo: localStorage.RightExamLoginInfo ? JSON.parse(localStorage.RightExamLoginInfo) : null,
    };
  },
  computed: {
    active: function () {
      const path = this.$route.path;
      if (path.includes("JGGK")) {
        return "6";
      } else if (path.includes("XXPX")) {
        return "2";
      } else if (path.includes("HYXW")) {
        return '7'
      } else if (path.includes("LearnAndDiscussion")) {
        return '8'
      } else {
        return '0'
      }
    },
    roles: function () {
      if (this.accountType == 4) {
        return [];
      }
      if (this.loginInfo) {
        return this.loginInfo.roles;
      } else {
        return [];
      }
    },
    accountType: function () {
      // console.log(this.loginInfo);
      if (this.loginInfo) {
        return this.loginInfo.accounttype ? this.loginInfo.accounttype : -1;
      } else {
        return -1;
      }
    },
    caseIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("案件浏览");
    },
    accountIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("账号查看");
    },
    roleIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("角色查看");
    },
    fourIsShow: function () {
      if (this.accountType == 4) {
        return true;
      }
      return this.roles.includes("更新协议");
    }
  },
  methods: {
    handleSelect(key) {
      if (key == 2) {
        this.$router.push('/XXPX')
        return
      } else if (key == 8) {
        this.$router.push('/LearnAndDiscussion')
        return
      } else if (key == 7) {
        this.$router.push('/HYXW')
        return
      } else if (key == 6) {
        this.$router.push('/JGGK')
        return
      }
      alert('开发中..')
    },
  },
  created() {
    console.log(this.accountType);
  }
};
</script>

<style scoped>
.AsideMenu {
  width: 100%;
  background-color: transparent;
}

.el-menu-vertical-demo {
  height: 100%;
  background-color: transparent;
  color: #fff;
}

.el-menu-item {
  color: #fff;
  font-size: 16px;
}

.el-menu-item i {
  color: #fff;
}

.is-active {
  background-color: #4472c4;
}

.el-menu-item:hover {
  background-color: #9fb3cf;
}
</style>