<template>
  <div>
    <div class="headerBox">
      <div class="logoBox">
        <img class="logo" src="@/assets/logo_name.png" alt="" />
      </div>
      <nav class="header-right">
        <ul>
          <li v-for="(item, index) in menuItems" :key="'header-nav' + index" :class="{ active: activeMenu === item }"
            @click="setActiveMenu(item)">
            {{ item }}
          </li>
        </ul>
      </nav>
      <div class="exitBox" v-if="false">
        <span class="accountText">账号：{{ loginName }}</span>
        <!-- <el-button type="text" class="exitBtn resetBtn" @click="resetPassword">重设密码</el-button> -->
        <el-button class="exitBtn" type="text" @click="handleClick">退出</el-button>
      </div>
    </div>
    <div class="titleBox">
      <div class="primary-title">
        {{ activeMenu }}
      </div>
      <div class="sub-title">
        {{ activeSubTile }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderNav",
  data() {
    return {
      loginName: localStorage.QCLoginName || "",
      menuItems: ['机构概况', '会议新闻', '质控调研', '学习培训'],
      activeMenu: '', // 默认选中的菜单项
      activeSubTile: "",
    };
  },
  watch: {
    '$route'(to) {
      this.updateActiveMenu(to.path)
      this.updateSubTitile(to.path)
    }
  },
  methods: {
    updateSubTitile(path) {
      if (path.includes("HY_list")) {
        this.activeSubTile = "重要会议"
        this.activeMenu = "会议新闻"
      } else if (path.includes('HY')) {
        this.activeMenu = "会议新闻"
      } else if (path.includes('ZCZBDetail')) {
        this.activeMenu = '学习培训';
        this.activeSubTile = this.$route.params.Name
      } else if (path.includes('VideoLesson')) { 
        this.activeMenu = "学习培训"
      } else {
        this.activeSubTile = ""
      }
    },
    updateActiveMenu(path) {
      if (path.includes("JGGK")) {
        this.activeMenu = '机构概况';
      } else if (path.includes("HYXW")) {
        this.activeMenu = '会议新闻';
      } else if (path.includes("XXPX")) {
        this.activeMenu = '学习培训';
      } else {
        this.activeMenu = '';
      }
    },
    setActiveMenu(item) {
      this.activeMenu = item; // 设置当前活动菜单项
      if (item.includes('学习培训')) {
        this.$router.push('/XXPX')
        return
      } else if (item.includes('质控调研')) {
        alert('开发中..')
        return
      } else if (item.includes('会议新闻')) {
        this.$router.push('/HYXW')
        return
      } else if (item.includes('机构概况')) {
        this.$router.push('/JGGK')
        return
      }
    },
    handleClick() {
      this.$confirm("您确定要退出当前账号，回到登录界面?", "退出登录", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      })
        .then(() => {
          // 确定
          this.$router.replace("/login");
        })
        .catch(() => {
          // 取消
        });
    },
    resetPassword() {
      this.$router.push('/resetPassword')
    }
  },
  created() {
    this.updateActiveMenu(this.$route.path)
    this.updateSubTitile(this.$route.path)
  }
};
</script>

<style scoped>
.headerBox {
  height: 100px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  box-sizing: border-box;
  background-color: #fff;
}

.logoBox {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
}

.logo {
  /* width: 40px; */
  height: 90px;
  margin-right: 10px;
}

.logoTitle {
  font-size: 25px;
  color: #4e8bfe;
  /* color: #fff; */
  letter-spacing: 3px;
}

.exitBox {
  margin-right: 50px;
}

.exitBtn {
  margin-left: 10px;
  color: #fff;
}

.exitBtn:hover {
  color: antiquewhite;
}

.accountText {
  color: #fff;
}

.header-right {
  padding-right: 80px;
}

.header-right ul {
  list-style-type: none;
  padding: 0;
}

.header-right li {
  display: inline;
  margin-left: 40px;
  cursor: pointer;
  color: #4e8bfe;
  position: relative;
}

.header-right li.active {}

.header-right li.active::before,
.header-right li.active::after {
  content: "";
  /* 创建空内容 */
  display: block;
  /* 将伪元素设置为块级元素 */
  width: 15px;
  /* 设置横杠的宽度 */
  height: 2px;
  /* 设置横杠的高度 */
  background: #4e8bfe;
  /* 设置横杠的颜色 */
  position: absolute;
  /* 使用绝对定位 */
  top: 50%;
  /* 垂直居中对齐 */
}

.header-right li.active::before {
  left: -20px;
}

.header-right li.active::after {
  right: -15px;
}

.titleBox {
  height: 80px;
  width: 100%;
  background: url("../../assets/top_bg.png") no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 20px;
  color: #fff;
  box-sizing: border-box;
  padding: 0 40px;
}

.primary-title {
  font-size: 40px;
}

.sub-title {
  font-size: 30px;
}
</style>